import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";

export const StyledTargets = styled.div`
  background-color: ${Colors.grayLight};
  padding-top: ${Rem(44)};
  padding-bottom: ${Rem(52)};
`;

export const StyledTargetsItems = styled.div`
  @media (${BP.ipad}) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

export const StyledTargetsItem = styled.div`
  &:not(:last-child) {
    margin-bottom: ${Rem(52)};
  }

  @media (${BP.ipad}) {
    position: relative;
    padding-left: ${Rem(30)};
    max-width: ${Rem(300)};

    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 1px;
      height: ${Rem(92)};
      left: 0;
      bottom: 0;
      background-color: ${Colors.yellow};
    }
  }
`;

export const StyledTargetsItemCounter = styled.div`
  font-size: ${Rem(14)};
  line-height: ${Rem(30)};
  letter-spacing: ${Rem(5)};
  color: ${Colors.yellow};
  margin-bottom: ${Rem(12)};

  @media (${BP.ipad}) {
    font-size: ${Rem(15)};
    margin-bottom: ${Rem(22)};
  }
`;

export const StyledTargetsItemText = styled.p`
  font-size: ${Rem(20)};
  line-height: ${Rem(25)};
`;
