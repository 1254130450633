import React from "react";
import {
  StyledTargets,
  StyledTargetsItems,
  StyledTargetsItem,
  StyledTargetsItemCounter,
  StyledTargetsItemText,
} from "./style.jsx";
import Intro from "../Intro/Intro";
import { StyledGridRow } from "../../commons/Grid";

export default ({ intro, items }) => {
  return (
    <StyledTargets>
      <StyledGridRow noMargin>
        <Intro {...intro} noPadding />
        <StyledTargetsItems>
          {items.map((item, index) => (
            <StyledTargetsItem key={index}>
              <StyledTargetsItemCounter>0{index + 1}</StyledTargetsItemCounter>
              <StyledTargetsItemText>{item}</StyledTargetsItemText>
            </StyledTargetsItem>
          ))}
        </StyledTargetsItems>
      </StyledGridRow>
    </StyledTargets>
  );
};
